import React from 'react'

import Checkbox from '@mui/material/Checkbox';

export default function InputNotificationPreferenceField(props) {

    const handleChange = event => {
        props.handleChange(event.target.checked);
    }

    return (<>
        <Checkbox
            defaultChecked
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />Please keep me posted.
    </>
    )
}