
import './App.css';
import Timer from './components/Countdown/Timer'
import Preloader from './components/Preloader/Preloader'
import FormDialog from "./components/EmailDialog/FormDialog";

function App() {
  return (
    <div className="App">
      { process.env.NODE_ENV !== "production" && (<small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>) }
      <div className="container">
        <h1>
          Smarter Routines.<br></br>
          Better Guidance.
        </h1>
        <h3>
        “Details matter, it's worth waiting to get it right.”<br></br>
        ― Steve Jobs
        </h3>
        <h2>
          Coming Soon
        </h2>
        <p>
        Fun and faster ways to create, perform, track and share your favorite fitness routines.<br></br>
        Smarter circuits that adapt to your fitness level whether your style is traditional, trendy or mix.
        </p>
        <Timer />
        <FormDialog />
        <Preloader />
      </div>
    </div>
  );
}

export default App;
