/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react';
//npm install @mui/material @emotion/react @emotion/styled --force
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import '../Optin/Optin.css';

import { emailSubscribe } from '../../API/API';
import { logger } from '../../utils/logger.ts';
import InputEmailField from './InputEmailField';
import InputNotificationPreferenceField from './NotifyUpdates';
import SubscriptionResponse from './SubscriptionResponse';

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const [emailState, setEmailState] = React.useState({
    email: '',
    isValid: false,
    dirty: false,
 });
 const [receiveUpdatesPreference, setReceiveUpdatesPreference] = React.useState(true);
 const [isSubscribed, setSubscribed] = React.useState(false);
 const [isVerified, setVerified] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setEmailState({
      email: '',
      isValid: false,
      dirty: false,
    });
  };

  const handleSubscription = async () => {
    const subscriptionResponse = await emailSubscribe({email: emailState.email, notifyLaunch: true, receiveUpdates: receiveUpdatesPreference});
    logger.log("Subscription response:", subscriptionResponse);
    setSubscribed(true);
    setVerified(subscriptionResponse.enabled)
    // If enabled is true then already verified. if false then "thank you. please verify"
  }

  const handleClose = async (e) => {
    setOpen(false);
    if(e.target.id === "btn-cancel") {
      // do nothingJ
    } else if (e.target.id === "btn-subscribe") {
      handleSubscription();
    }
  };


  // npm install validator
  const handleEmailChange = (value, isValid) => {
    setEmailState({ email: value, isValid: isValid, dirty: false });
  }

  const handleNotificationPreferenceChange = (value) => {
    setReceiveUpdatesPreference(value);
  }

  return (
    <div>

      {!isSubscribed && (
        <div className="optin">
        <p>Be the first one to know our launch.</p>
        <button onClick={handleClickOpen}>Notify Launch</button>
      </div>
      )}
      {isSubscribed && (
        <SubscriptionResponse props={{isVerified: isVerified, email: emailState.email, notifyLaunch: true, receiveUpdates: receiveUpdatesPreference}}/>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Subscribe to our website by entering your email id here. We will notify you once we launch. We can send updates too – if you prefer.
          </DialogContentText>
          <InputNotificationPreferenceField
              handleChange={handleNotificationPreferenceChange}
            />
          <InputEmailField placeholder=""
                    helperText="(Required)"
                    label="Email Address*"
                    type="email"
                    fieldName="Email"
                    handleEmailChange={handleEmailChange}
                    isFullWidth={true}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="btn-cancel"
            onClick={handleClose}>
            Cancel
          </Button>
          <Button id="btn-subscribe"
            disabled={!emailState.isValid || emailState.dirty}
            type="submit"
            onClick={handleClose}>
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}