import React from 'react'
import { emailResend } from '../../API/API';

import { logger } from '../../utils/logger.ts';

const handleResend = async (props) => {
    const resendResponse = await emailResend({email: props.email, notifyLaunch: true, receiveUpdates: props.receiveUpdatesPreference});
    logger.log("Resend response:", resendResponse);
  };

export default function SubscriptionResponse({props}) {
    logger.log("Subscription response props:", props);
    return (
        <>
        <p>Thanks for subscribing.</p>
        {!props.isVerified && (
            <>
            <p>You should receive a confirmation email soon.</p>
            <p>No email? No worries. We can resend.
            <a id="myLink" title="Resend" href="#" onClick={handleResend}>Resend Email Verification</a></p>
        </>
        )}
        {props.isVerified && (
            <p>You have verified your email.</p>
        )}
        </>
    )
}