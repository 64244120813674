/*
    API Call centralization and error handling
    Login / Register Don't need token
    Subsequent calls need valid token
*/
import { logger } from "../utils/logger.ts";

const API_END_POINT = process.env.REACT_APP_SERVER_URL

export async function emailSubscribe(params) { // email=input, launchNotify=true, receiveUpdate=input
    return apiCall('/email/subscribe', 'POST', params);
}
export async function emailResend(params) { // email=input, launchNotify=true, receiveUpdate=input
    return apiCall('/email/resend', 'POST', params);
}

function apiCall(url, method, params) {

    url = API_END_POINT + url;
    logger.log("API URL: ", url);
    var fetchParams = { method: 'POST', body: new URLSearchParams(params) };

    return fetch(url, fetchParams)
        .then((response) => {
            logger.log("API Response: ", response);
            if (!response.ok) {
                if (response.status === 504) {
                    throw Error("Server is unavailable. Please try again later.");
                } else {
                    // setError(true)
                    throw Error("Something went wrong.");
                }
            } else if (response.status === 404) {
                // setNotFound(true)
            } else if (response.status > 500) {
                // setServerError(true)
            } else {
                return response.json();
            }
        })
        .then((data) => {
            logger.log("API DATA: ", data);
            return data; 
          })
        .catch((error) => {
            if (error.response) { // if there is response, it means its not a 50x, but 4xx
                //logger.error("Error 4xx: ", error)
            } else {   // gets activated on 50x errors, since no response from server
                // do whatever
                //logger.error("Error 50x: ", error)
            }
            throw error;
        })
}
