import React, { useState } from 'react'
//npm install @material-ui/core
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import isEmail from 'validator/lib/isEmail';
import { logger } from '../../utils/logger';

export default function InputEmailField(props) {   
    const [value, setValue] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [dirty, setDirty] = useState(false);
    
    const handleEmailChange = event => {
        const val = event.target.value;
        props.handleEmailChange(val, isEmail(val));                      
        
        if(isEmail(val)) {
            setIsValid(true);              
        } else {
            setIsValid(false);              
        }
        setValue(val);
    }
    
    return (
        <React.Fragment>
            <FormControl fullWidth={props.isFullWidth} >
                <TextField         
                    error={dirty && isValid === false}                                        
                    onBlur={() => setDirty(true)}
                    id={props.fieldName}                    
                    label={props.label}
                    name={props.fieldName}                    
                    variant="standard" 
                    size={'small'}
                    helperText={props.helperText}
                    value={value}
                    InputLabelProps={{
                      //shrink: true,
                    }}
                    onChange={(e) => handleEmailChange(e)}
                    onPaste={(e) => handleEmailChange(e)}
                    style={{marginTop: 1}}
                />
                
            </FormControl>
        </React.Fragment>
    )
}